<template>
  <div class="component-container">
    <div class="content-container">
      <div class="header-filter">
        <el-form
          ref="filterForm"
          :model="filterForm"
          label-suffix=":"
          :inline="true"
          class="header-filter-form"
        >
          <el-form-item label="日期">
            <el-date-picker
              v-model="filterForm.date"
              align="left"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              size="small"
              clearable
              class="filter-element"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="账户">
            <el-select
              v-model="filterForm.accountId"
              size="small"
              clearable
              class="filter-element"
            >
              <el-option
                v-for="(ac, acIndex) in accountList"
                :key="acIndex"
                :label="ac.name"
                :value="ac._id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收/支">
            <el-select
              v-model="filterForm.method"
              size="small"
              clearable
              class="filter-element"
            >
              <el-option label="收入" value="income" />
              <el-option label="支出" value="spending" />
            </el-select>
          </el-form-item>
          <el-form-item label="账单类型">
            <el-select
              v-model="filterForm.billType"
              size="small"
              filterable
              clearable
              class="filter-element"
            >
              <el-option
                v-for="(bt, btIndex) in allBillTypes"
                :key="btIndex"
                :label="bt.name"
                :value="bt.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="描述">
            <el-input
              v-model="filterForm.description"
              size="small"
              clearable
              class="filter-element"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList"
              >查询</el-button
            >
            <el-button type="" size="small" @click="handleClearFilter"
              >清空</el-button
            >
          </el-form-item>
        </el-form>
        <el-button @click="sortVisible = true" type="warning">排 序</el-button>
        <el-button @click="handleAdd" type="primary">新 增</el-button>
      </div>
      <div class="bill-table">
        <el-table :data="list" style="width: 100%" size="medium">
          <el-table-column prop="date" label="日期"> </el-table-column>
          <el-table-column prop="time" label="时间"> </el-table-column>
          <el-table-column prop="account" label="账户">
            <template slot-scope="{ row }">
              {{ accountName(row) }}
            </template>
          </el-table-column>
          <el-table-column prop="method" label="收/支">
            <template slot-scope="{ row }">
              <el-tag type="success" v-if="row.method === 'spending'">
                支出
              </el-tag>
              <el-tag type="danger" v-if="row.method === 'income'">
                收入
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="金额"> </el-table-column>
          <el-table-column prop="billType" label="账单类型">
            <template slot-scope="{ row }">
              {{ billTypeName(row) }}
            </template>
          </el-table-column>
          <el-table-column prop="description" label="描述"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="small" @click="handleEdit(row)">
                编辑
              </el-button>
              <el-button
                type="danger"
                size="small"
                @click="handleDelete(row._id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pagination.total"
          :page-size="pagination.size"
          :current-page.sync="pagination.page"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleBeforeClose"
      class="add-dialog"
      :title="dialogTitle"
      :close-on-click-modal="false"
    >
      <el-form
        ref="add-bill-form"
        label-suffix=":"
        :model="billForm"
        label-width="100px"
        class="add-bill-form"
      >
        <el-form-item label="日期" prop="date">
          <el-date-picker
            ref="form-item-date"
            v-model="billForm.date"
            align="right"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间" prop="time">
          <el-time-picker
            ref="form-item-time"
            v-model="billForm.time"
            :picker-options="{
              selectableRange: '00:00:00 - 23:59:59',
            }"
            value-format="HH:mm:ss"
            placeholder="请选择时间"
          >
          </el-time-picker>
        </el-form-item>

        <el-form-item label="账单类型" prop="billType">
          <div class="bill-type-container">
            <el-button
              @click="typeDrawerShow = true"
              style="margin-right: 16px"
            >
              选择账单类型
            </el-button>
            <BillTypeItem
              :billTypeData="selectedType"
              :showClose="true"
              v-if="selectedType"
              @handleCloseBillType="handleCloseBillType"
            />
          </div>
        </el-form-item>
        <el-form-item label="收支类型">
          <el-tag type="success" v-if="billForm.method === 'spending'">
            支出
          </el-tag>
          <el-tag type="danger" v-if="billForm.method === 'income'">
            收入
          </el-tag>
        </el-form-item>

        <el-form-item label="账户" prop="accountId">
          <el-select
            v-model="billForm.accountId"
            placeholder="请选择账户"
            ref="form-item-accountId"
          >
            <el-option
              v-for="(ac, acIndex) in accountList"
              :key="acIndex"
              :label="ac.name"
              :value="ac._id"
              :disabled="ac.status === 0"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="金额" prop="amount">
          <el-input
            v-model="billForm.amount"
            placeholder="请输入金额"
            ref="form-item-amount"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="账单内容" prop="description">
          <el-input
            v-model="billForm.description"
            placeholder="请输入内容"
            ref="form-item-description"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>

        <el-button type="primary" @click="handleSave"
          >确 定 (ALT + ENTER)</el-button
        >
      </span>
    </el-dialog>

    <el-drawer
      title="选择类型"
      :visible.sync="typeDrawerShow"
      direction="rtl"
      size="600px"
      @close="handleCloseDrawer"
      @opened="handleOpenedDrawer"
      class="type-drawer"
    >
      <div class="type-drawer-content">
        <div class="type-drawer-filter">
          <span class="drawer-filter-title"> 过滤搜索：</span>
          <el-input
            ref="typeQuickFilter"
            v-model="typeQuickFilter"
            placeholder="过滤"
            class="drawer-filter-input"
          />
        </div>
        <BillTypeSelector
          ref="bill-type-selector"
          :typeQuickFilter="typeQuickFilter"
          :billTypeIncome="billTypeIncome"
          :billTypeSpending="billTypeSpending"
          @set-type="handleSetBillType"
        />
      </div>
    </el-drawer>

    <!-- 排序的对话框 -->
    <BillSortDialog :sortVisible.sync="sortVisible" />
  </div>
</template>

<script>
import Api from "@/api";

import * as defaultTypeData from "@/views/type-manage/component/defaultData.js";
import BillTypeSelector from "@/components/BillTypeSelector/BillTypeSelector.vue";
import BillTypeItem from "@/components/BillTypeSelector/BillTypeItem.vue";
import BillSortDialog from "@/components/BillSortDialog/index.vue";
import dayjs from "dayjs";

export default {
  name: "Bill",
  components: {
    BillTypeSelector,
    BillTypeItem,
    BillSortDialog,
  },
  computed: {
    allBillTypes() {
      let all = [];
      const t_1 = JSON.parse(JSON.stringify(this.billTypeSpending));
      const t_2 = JSON.parse(JSON.stringify(this.billTypeIncome));
      all.push(...t_1);
      all.push(...t_2);
      return all;
    },
    selectedType() {
      let res = null;

      if (this.billForm.billType) {
        const one = this.allBillTypes.find(
          (o) => o.id === this.billForm.billType
        );
        if (one) {
          res = JSON.parse(JSON.stringify(one));
        }
      }

      return res;
    },
    dialogTitle() {
      let title = "";

      if (this.billForm.id) {
        title = "编辑账单";
      } else {
        title = "新增账单";
      }

      return title;
    },
  },
  watch: {
    "billForm.billType": {
      handler(val) {
        if (val) {
          const typeOne = this.allBillTypes.find((o) => o.id === val);
          if (typeOne) {
            this.billForm.method = typeOne.method;
          } else {
            this.billForm.method = "";
          }
        } else {
          this.billForm.method = "";
        }
      },
      deep: true,
      immediate: true,
    },
    "billForm.accountId": {
      handler(val) {
        if (val) {
          const accOne = this.accountList.find((o) => o._id === val);
          if (accOne) {
            this.billForm.accountName = accOne.name;
          } else {
            this.billForm.accountName = "";
          }
        } else {
          this.billForm.accountName = "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      filterForm: {
        date: null,
        accountId: null,
        method: null,
        billType: null,
        description: null,
      },

      billTypeIncome: [],
      billTypeSpending: [],
      accountList: [],
      list: [
        // {
        //   id: 1,
        //   accountId: 2,
        //   accountName: "生活卡",
        //   method: "spending",
        //   amount: 24.5,
        //   billType: 2,
        //   description: "吃了午餐",
        // },
      ],
      pagination: {
        total: 0,
        size: 10,
        page: 1,
      },

      dialogVisible: false,
      billForm: {
        id: "",
        accountId: "",
        accountName: "",
        billType: "",
        date: null,
        time: null,
        method: "",
        amount: "",
        description: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      typeDrawerShow: false,
      typeQuickFilter: "",

      /** 排序的对话框 */
      sortVisible: false,
    };
  },
  mounted() {
    this.getAllAccount();
    this.getAllBillType();
    this.getList();
    document.addEventListener("keydown", this.initListenKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.initListenKey);
  },
  methods: {
    /**
     * 初始化本页的键盘按钮监听
     * @param {Object} e 键盘事件
     */
    initListenKey(e) {
      //定义一个变量 方便后面保存 事件对象
      let $Event = undefined;
      // 将事件对象保存
      $Event = e;
      // // 将用户按下的键同义转换成大写
      const keyCode = $Event.key.toLocaleUpperCase();
      // const ctrlKey = $Event.ctrlKey || e.metaKey;
      const altKey = $Event.altKey;
      // //保存 用户按下的键，方便后续出传值
      if (altKey) {
        if (["N", "T", "ENTER"].includes(keyCode)) {
          this.handleHotKey(`ALT-${keyCode}`);
          $Event.preventDefault();
        }
      } else {
        if (this.typeDrawerShow) {
          if (
            [
              "ARROWUP",
              "ARROWDOWN",
              "ARROWLEFT",
              "ARROWRIGHT",
              "ENTER",
            ].includes(keyCode)
          ) {
            this.handleHotKey(keyCode);
            $Event.preventDefault();
          }
        }
      }
    },
    /**
     * 处理各种本页面里的键盘按键事件
     * @param {string} code 键盘的按键，为大写，如果带有alt，如alt+n，则为"ALT-N"
     */
    handleHotKey(code) {
      if (!this.dialogVisible && code !== "ALT-N") {
        return;
      }
      if (code === "ENTER") {
        if (this.typeDrawerShow) {
          // 如果是选择账单类型框框弹出时，则选中账单类型
          if (this.$refs["bill-type-selector"]) {
            this.$refs["bill-type-selector"].handleEnter();
          }
        }
      } else if (code === "ALT-ENTER") {
        if (!this.typeDrawerShow) {
          // 如果不是选择账单类型框框弹出时，而且得是新增对话框打开时，则回车保存
          this.handleSave();
        }
      } else if (code === "ALT-N") {
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs["form-item-date"].focus();
          this.billForm.date = dayjs().format("YYYY-MM-DD");
        });
      } else if (code === "ALT-T") {
        this.typeDrawerShow = true;
      } else if (
        ["ARROWUP", "ARROWDOWN", "ARROWLEFT", "ARROWRIGHT"].includes(code)
      ) {
        if (this.$refs["bill-type-selector"]) {
          this.$refs["bill-type-selector"].handleArrow(code);
        } else {
          console.log(
            "this.$refs['bill-type-selector']",
            "no refs to handleArrow",
            code
          );
        }
      } else {
        this.$message.success(code);
      }
    },
    /**
     * 获取账户列表
     */
    async getAllAccount() {
      this.accountList = [];
      const res = await Api.AccountApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        this.accountList = records || [];
      } else {
        this.$message.error("账户列表请求失败");
      }
    },
    /**
     * 获取所有账单类型
     */
    async getAllBillType() {
      this.billTypeSpending = [];
      this.billTypeIncome = [];

      const res = await Api.BillTypeApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        this.billTypeSpending = JSON.parse(
          JSON.stringify(defaultTypeData.spendingTypes)
        );
        this.billTypeIncome = JSON.parse(
          JSON.stringify(defaultTypeData.incomeTypes)
        );

        const _incomeCustomize = records.filter(
          (item) => item.method === "income"
        );
        const _spendingCustomize = records.filter(
          (item) => item.method === "spending"
        );

        _incomeCustomize.forEach((item) => (item.id = item._id));
        _spendingCustomize.forEach((item) => (item.id = item._id));

        this.billTypeIncome.push(..._incomeCustomize);
        this.billTypeSpending.push(..._spendingCustomize);
      } else {
        this.$message.error("账单类型请求失败");
      }
    },
    /**
     * 清空过滤搜索
     */
    handleClearFilter() {
      ({ filterForm: this.filterForm } = this.$options.data());
      this.getList();
    },
    /**
     * 获取分页列表
     */
    async getList() {
      const { page, size } = this.pagination;
      const {
        date, // "2023-12-07" || "2023-12-" || "2023-12"
        accountId,
        method,
        billType,
        description,
      } = this.filterForm;

      const res = await Api.BillApi.query({
        page,
        size,
        date,
        accountId,
        method,
        billType,
        description,
      });
      if (res && res.code === 0) {
        const { data } = res;
        const { records, total } = data;
        this.list = records;
        this.pagination.total = total;
      } else {
        this.$message.error("列表请求失败");
      }
    },
    /**
     * 根据行的账户id获取账户名字
     * @param {object} row 行数据
     * @returns 返回了账户名
     */
    accountName(row) {
      const one = this.accountList.find((item) => item._id === row.accountId);
      let res = "";
      if (one) {
        res = one.name;
      } else {
        res = row.accountName + "（已删除）";
      }
      return res;
    },
    /**
     * 根据行的账单类型id获取账单类型
     * @param {object} row 行数据
     * @returns 返回了账单类型
     */
    billTypeName(row) {
      const one = this.allBillTypes.find((item) => item.id === row.billType);
      let res = "";
      if (one) {
        res = one.name;
      }
      return res;
    },
    /**
     * 点击编辑
     * @param {object} row 行数据
     */
    handleEdit(row) {
      this.billForm.id = row._id;
      this.billForm.accountId = row.accountId;
      this.billForm.accountName = row.accountName;
      this.billForm.billType = row.billType;
      this.billForm.date = row.date;
      this.billForm.time = row.time;
      this.billForm.method = row.method;
      this.billForm.amount = row.amount;
      this.billForm.description = row.description;

      this.dialogVisible = true;
    },
    /**
     * 删除账单
     * @param {string} id 账单id
     */
    async handleDelete(id) {
      this.$confirm("是否要删除这一账单？", "提示", {
        confirmButtonText: "确定，删除账单",
        cancelButtonText: "取消，保留账单",
      })
        .then(async () => {
          const res = await Api.BillApi.removeSync(id);
          if (res && res.code === 0) {
            const { data } = res;
            if (data === true) {
              this.$message.success("删除成功");
            }
            this.getList();
          } else {
            this.$message.error("删除账单失败");
          }
        })
        .catch(() => {
          this.$message.info("取消了删除账单");
        });
    },
    /**
     * 点击新增
     */
    handleAdd() {
      this.dialogVisible = true;
    },

    /**
     * 关闭对话框的回调函数
     */
    handleBeforeClose() {
      this.handleClose();
    },
    /**
     * 关闭对话框
     */
    handleClose() {
      ({ billForm: this.billForm } = this.$options.data());
      this.dialogVisible = false;
    },
    /**
     * 關閉抽屜
     */
    handleCloseDrawer() {
      this.resetBillTypeSelector();
      // done();
    },
    /**
     * 打开抽屉完成之后的回调
     */
    handleOpenedDrawer() {
      // focus
      this.$refs["typeQuickFilter"].focus();
    },
    /**
     * 保存
     */
    async handleSave() {
      const billDto = {
        date: this.billForm.date, // 2022-10-21
        time: this.billForm.time, // 11:32:22
        billType: this.billForm.billType, // b4e31231d8
        method: this.billForm.method, // "spending", "income"
        accountId: this.billForm.accountId, // a1b2c3d4
        amount: Number(this.billForm.amount), // 10
        description: this.billForm.description, // "午餐"
        accountName: this.billForm.accountName, // 生活卡
      };

      if (this.billForm.id) {
        const res = await Api.BillApi.updateSync({
          id: this.billForm.id,
          ...billDto,
        });
        if (res && res.code === 0) {
          this.$message.success("编辑成功");
        } else {
          this.$message.error("编辑失败");
        }
      } else {
        const res = await Api.BillApi.addSync(billDto);
        if (res && res.code === 0) {
          this.$message.success("保存成功");
        } else {
          this.$message.error("保存失败");
        }
      }

      this.handleClose();
      this.getList();
    },
    /**
     * 选择了账单类型
     * @param {string} typeId 类型id
     */
    handleSetBillType(typeId) {
      this.billForm.billType = typeId;
      this.typeDrawerShow = false;
    },
    /**
     * 重置抽屜數據
     */
    resetBillTypeSelector() {
      ({
        typeDrawerShow: this.typeDrawerShow,
        typeQuickFilter: this.typeQuickFilter,
      } = this.$options.data());
    },
    /**
     * 删除了账单类型
     */
    handleCloseBillType() {
      this.billForm.billType = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  padding: 27px 27px 0px 27px;
  .header-filter-form {
    .filter-element {
      width: 140px;
    }
  }
}
.bill-table {
  padding: 27px 27px 0px 27px;
  ::v-deep .el-table__header-wrapper {
    .el-table__cell {
      background-color: rgb(246, 246, 246);
      border-bottom: 1px solid rgb(221, 221, 221);
      color: black;
      font-weight: normal;
    }
  }
}
.pagination {
  padding: 27px 27px 27px 27px;
  bottom: 0px;
}
.add-dialog {
  .add-bill-form {
    .bill-type-container {
      display: flex;
      align-items: center;
    }
  }
}
.type-drawer {
  .type-drawer-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    .type-drawer-filter {
      height: 60px;
      .drawer-filter-title {
        margin: 0px 0px 10px 10px;
      }
      .drawer-filter-input {
        width: 400px;
        margin: 0px 10px 10px 0px;
      }
    }
  }
}
</style>
